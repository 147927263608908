import { action, makeAutoObservable, observable } from 'mobx'
import { api, AuthCurrency } from 'utils'

class PaymentCurrency {
	currency: AuthCurrency = AuthCurrency.ethereum


	constructor() {
		makeAutoObservable(this)
	}


	changeCurrency() {
		this.currency = this.currency === AuthCurrency.ethereum ? AuthCurrency.polygon : AuthCurrency.ethereum;
	}


	setDefaultCurrency() {
		this.currency = AuthCurrency.ethereum;
	}


	async getExchangeRateETHUSD() {
		const response = await api.get(`https://api.gemini.com/v1/pubticker/ethusd`)
		if (response.status !== 200)
			throw new Error(
				response.data && response.data.Description
					? response.data.Description
					: 'Error retrieving user profiles'
			)
		return response.data
	}


	async getExchangeRateMATICUSD() {
		const response = await api.get(`https://api.gemini.com/v1/pubticker/maticusd`)
		if (response.status !== 200)
			throw new Error(
				response.data && response.data.Description
					? response.data.Description
					: 'Error retrieving user profiles'
			)
		return response.data
	}
}

export default new PaymentCurrency()
