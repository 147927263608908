import { goerli, mainnet, polygon, polygonMumbai } from 'wagmi/chains'
import { configureChains, createConfig } from 'wagmi'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'

const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID ?? ''
const chains = process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENVIRONMENT !== 'DEV' ? [mainnet, polygon] : [mainnet, polygon, goerli, polygonMumbai]
const { publicClient, webSocketPublicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  publicClient,
  webSocketPublicClient
})

const ethereumClient = new EthereumClient(wagmiConfig, chains)

export const getWagmiConfig = () => wagmiConfig
export const getEthereumClient = () => ethereumClient
