import { action, makeAutoObservable, observable } from 'mobx'
import { toast } from '../App'
import LoadingStore from 'store/loading'
import TransactionStore from 'store/transaction'
import WalletConnectStore from 'store/walletConnect'
import {
  ABI_ERC20_GOERLI,
  apiError,
  downloadTableExcel,
  getUniswapObjectERC721,
  getUniswapObjectERC721WalletConnect
} from '../utils'
import { api } from 'utils/config'
import type { IInvestorData } from '../models'
import { ContractDecimal } from 'utils/blockchain/ERC20'
import { IAUTHPrice } from '../models'
import { ethers, JsonRpcSigner } from 'ethers'


//@ts-ignore
const { ethereum } = window

const defaultAUTHPrice = {
  ETHPriceForAUTH: 0,
  USDPriceForAUTH: 0
}

class AuthToken {
  AUTHPrice: IAUTHPrice = defaultAUTHPrice
  investorData: IInvestorData | undefined = undefined
  claimCountUpdate: number = 0


  constructor() {
    makeAutoObservable(this)
  }

  setAUTHPrice(data: IAUTHPrice) {
    this.AUTHPrice = data
  }

  setInvestorData(data: IInvestorData) {
    this.investorData = data
  }


  setClaimCountUpdate() {
    this.claimCountUpdate = this.claimCountUpdate + 1
  }


  async getAuthIdentityDivideStatistic(pageNumber: number, pageSize: number) {
    const response = await api.get(
      `api/AUTH/GetAuthIdentityDivideStatisticById`,
      {
        params: {
          startTotalItems: 0,
          pageNumber,
          pageSize
          // sorting: sortData.type,
          // sortingType: sortData.direction
        }
      }
    )
    if (response.status !== 200) apiError(response)

    return response.data
  }


  async getAuthMessageDivideStatistic(pageNumber: number, pageSize: number) {
    const response = await api.get(
      `api/AUTH/GetAuthMessageDivideStatisticById`,
      {
        params: {
          startTotalItems: 0,
          pageNumber,
          pageSize
          // sorting: sortData.type,
          // sortingType: sortData.direction
        }
      }
    )
    if (response.status !== 200) apiError(response)

    return response.data
  }


  async getAuthPageInfo(pageNumber: number, pageSize: number) {
    const response = await api.get(
      `api/AUTH/getAuthPageInfo`,
      {
        params: {
          pageNumber,
          pageSize
          // sorting: sortData.type,
          // sortingType: sortData.direction
        }
      }
    )
    if (response.status !== 200) apiError(response)

    return response.data
  }


  async getClaimInfoByStatus(pageNumber: number, pageSize: number) {
    const response = await api.get(
      `api/AUTH/GetClaimInfoByStatus`,
      {
        params: {
          pageNumber,
          pageSize,
          sorting: 0
          // status: 0 | 1| 2
        }
      }
    )
    if (response.status !== 200) apiError(response)

    return response.data
  }

  //
  // async getBackedProfiles(page: number, pageSize: number) {
  //   const response = await api.get(
  //     `api/AUTH/GetBackedProfiles`,
  //     {
  //       params: {
  //         page,
  //         pageSize,
  //       },
  //     },
  //   )
  //   if (response.status !== 200) apiError(response)
  //
  //   return response.data
  // }
  //
  //
  // async getBackedPosts(page: number, pageSize: number) {
  //   const response = await api.get(
  //     `api/AUTH/GetBackedPosts`,
  //     {
  //       params: {
  //         page,
  //         pageSize,
  //       },
  //     },
  //   )
  //   if (response.status !== 200) apiError(response)
  //
  //   return response.data
  // }


  async getBalanceAvailableToClaim(publicationId?: number) {
    const paramsData = publicationId ? { publicationId } : {}
    const response = await api.get(
      `api/AUTH/GetBalanceAvailableToClaim`,
      {
        params: paramsData
      }
    )
    if (response.status !== 200) apiError(response)

    return response.data
  }


  async getAuthBalance() {
    const provider = new ethers.BrowserProvider(ethereum)
    const signer = await provider.getSigner()

    // Need check Work with this too
    // const signer = new JsonRpcSigner(provider, userWallet)

    await ethereum.request({ method: 'eth_requestAccounts' }) //-◄-Enable ethereum
    const userWallets = await ethereum.request({ method: 'eth_accounts' })
    const userWallet = userWallets[0]
    const AUTH_ERC20 = new ethers.Contract(
      process.env.REACT_APP_ERC20_ADDRESS_OBJECT ?? '',
      ABI_ERC20_GOERLI,
      signer
    )
    const balanceOf = await AUTH_ERC20.balanceOf(userWallet)
    console.log('balanceOf', balanceOf)
    return +ethers.formatUnits(balanceOf, ContractDecimal)
  }


  async getAuthBalanceWalletConnect() {
    const { signer } = await WalletConnectStore.getWeb3ProviderAndSigner()

    const userWallet = WalletConnectStore.address

    const AUTH_ERC20 = new ethers.Contract(
      process.env.REACT_APP_ERC20_ADDRESS_OBJECT ?? '',
      ABI_ERC20_GOERLI,
      signer
    )

    const balanceOf = await AUTH_ERC20.balanceOf(userWallet)
    console.log('balanceOf', balanceOf)
    return +ethers.formatUnits(balanceOf, ContractDecimal)
  }


  async getClaimTransactionInfo(count: number) {
    const response = await api.post(
      `api/AUTH/CreateClaimTransactionInfo`,
      null,
      {
        params: {
          count
        }
      }
    )
    if (response.status !== 200) apiError(response)

    return response.data
  }


  async sendEthToAnotherWallet(GasEstimate: number) {
    if (ethereum) {
      const provider = new ethers.BrowserProvider(ethereum)
      const signer = await provider.getSigner()

      await ethereum.request({ method: 'eth_requestAccounts' }) //-◄-Enable ethereum
      const userWallets = await ethereum.request({ method: 'eth_accounts' })
      const userWallet = userWallets[0]

      //Anton problem decimal to double
      const amountWei = ethers.parseUnits(String(GasEstimate), 'gwei')
      console.log('amountWei', amountWei)
      return await signer.sendTransaction({
        from: userWallet,
        to: process.env.REACT_APP_APPROVE_ADDRESS,
        value: BigInt(amountWei)
      })
    } else if (WalletConnectStore.provider?.connected) {
      const { signer } = await WalletConnectStore.getWeb3ProviderAndSigner()

      const userWallet = WalletConnectStore.address
      const amountWei = ethers.parseUnits(String(GasEstimate), 'gwei')
      console.log('amountWei', amountWei)
      return await signer.sendTransaction({
        from: userWallet,
        to: process.env.REACT_APP_APPROVE_ADDRESS,
        value: BigInt(amountWei)
      })
    }
  }


  async CheckTransactionStatus(id: string) {
    const response = await api.get(
      `api/AUTH/CheckTransactionStatus`,
      {
        params: {
          id
        }
      }
    )
    if (response.status !== 200) apiError(response)

    return response.data
  }


  async Claim(transactionId: string, hash: string) {
    const response = await api.post(
      `api/AUTH/Claim`,
      null,
      {
        params: {
          payedClaimTransactionHash: hash,
          claimAuthPayoutId: transactionId
        }
      }
    )
    if (response.status !== 200) apiError(response)

    return response.data
  }

  //
  // async getAuthExchangeRate() {
  // 	return  fetch('https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD,ETH')
  // 		.then((response) => {
  // 			return response.json();
  // 		})
  // 		.then((data) => {
  // 			return data
  // 		});
  // }


  async getAUTHPrice() {
    return fetch('https://api.coingecko.com/api/v3/simple/price?ids=authencity&vs_currencies=ETH,USD')
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        return data
      })
      .catch(e => console.log('getAUTHPrice', e))
  }


  async getPreSalePrice() {
    const response = await api.get(`api/Investor/GetPreSalePriceForOne`)

    if (response.status !== 200) apiError(response)

    return response.data
  }


  async getInvestorData() {
    const response = await api.get(`api/Investor/GetInvestor`)

    if (response.status !== 200) apiError(response)
    return response.data
  }


  async getAmountForInvestor() {
    const response = await api.get(`api/Investor/BuyForInvestor`)

    if (response.status !== 200) apiError(response)

    return response.data
  }


  async checkTransactionInvestor(transactionHash: string) {
    const response = await api.get(
      `api/Investor/CheckTransactionInvestor`,
      {
        params: {
          transactionHash
        }
      })

    if (response.status !== 200) apiError(response)

    return response.data
  }


  async deletePreSaleGeiInfo() {
    const response = await api.delete(`api/Investor/DeletePreSaleGeiInfo`)

    if (response.status !== 200) apiError(response)

    return response.data
  }


  async sendInvestorAmount(amount: string) {
    if (ethereum) {
      const provider = new ethers.BrowserProvider(ethereum)
      const signer = await provider.getSigner()

      await ethereum.request({ method: 'eth_requestAccounts' })
      const userWallets = await ethereum.request({ method: 'eth_accounts' })
      const userWallet = userWallets[0]

      signer.sendTransaction({
        from: userWallet,
        // to: '0x03C688d1583C19E6bd8110002442208E14d5e571',
        to: process.env.REACT_APP_APPROVE_ADDRESS,
        value: amount
      }).then(async (res: any) => {
        await this.checkTransactionInvestor(res.hash)
        const response: IInvestorData = await this.getInvestorData()
        this.setInvestorData(response)
        LoadingStore.setLoading(false)
        TransactionStore.setIsActive(false)
      }).catch((e: any) => {
        this.deletePreSaleGeiInfo()
        LoadingStore.setLoading(false)
        TransactionStore.setIsActive(false)
        toast({
          type: 'warning',
          message: e.code === 'INSUFFICIENT_FUNDS' ? 'Insufficient tokens for transfer' : 'User denied transaction signature.'
        })
      })
    }
  }


  async generateStatistic(userId: string, type: 0 | 1 | 2) {
    const response = await api.get(`api/AUTH/generateStatistic`, {
      params: { userId, type }
    })

    if (response.status !== 200) apiError(response)

    return response.data
  }


  async downloadStatistic(userId: string, fileName?: string) {
    const response = await api.get(`api/AUTH/downloadStatistic`, {
      params: { userId },
      responseType: 'blob'
    })

    if (response.status !== 200) apiError(response)

    downloadTableExcel(response.data, fileName)
    return response.data
  }


  async getTokensFromOpenSea(value: { wallet: string, tokenId?: string }, next?: string | null) {
    const wallet = '0xDDd3964D75D59B6B6d5c31eB313BBA5EBF076364'
    const options = {
      method: 'GET',
      headers: { accept: 'application/json', 'X-API-KEY': '7ddc4b9f555d4bdba2ed0bc1d6421790' }
    }

    const url = `https://api.opensea.io/api/v1/assets?owner=${wallet}${value.tokenId ? `&token_ids=${value.tokenId}` : ''}${next ? `&cursor=${next}` : ''}&asset_contract_address=${process.env.REACT_APP_UNISWAP_CONTRACT_ADDRESS}&order_direction=desc&limit=20&include_orders=false`

    return fetch(url, options)
      .then(response => response.json())
      .then(response => response)
      .catch(err => console.error(err))
  }


  // Change ethereum || WalletConnectStore.provider?.connected
  async stakeToken(tokenId: string) {
    if (!(ethereum || WalletConnectStore.provider?.connected)) {
      return toast({
        type: 'warning',
        message: 'This feature is not available because you are not using MetaMask'
      })
    }
    try {
      let UniswapERC721: any
      let userWallet: any

      // getUniswapObjectERC721WalletConnect need uncomment and fix chainId
      const data = ethereum ? await getUniswapObjectERC721() : await getUniswapObjectERC721WalletConnect()

      if (data) {
        UniswapERC721 = data.UniswapObjectERC721
        userWallet = await data.signer.getAddress()
      }

      if (!UniswapERC721 || !userWallet) {
        return LoadingStore.setLoading(false)
      }

      UniswapERC721.transferFrom(userWallet, process.env.REACT_APP_APPROVE_ADDRESS, tokenId)
        .then(async (res: any) => {
          if (res) {
            console.log('res', res)
            // send hash and tokenId to server
            toast({
              type: 'success',
              message: 'Stake successfully created'
            })

          } else {
            toast({
              type: 'warning',
              message: 'Stake not created'
            })
          }
          LoadingStore.setLoading(false)
        }).catch((e: any) => {
          console.log('e', e)
          const errorMessage = () => {
            if (e.error) {
              return `Metamask error code: ${e.error?.code} ${e.error?.message}`
            } else {
              return e.message ? e.message : 'Stake error'
            }
          }
          LoadingStore.setLoading(false)
          toast({
            type: 'warning',
            message: errorMessage()
          })
        }
      )
    } catch (e) {
      LoadingStore.setLoading(false)
      toast({
        type: 'error',
        message: 'Stake error'
      })
    }
  }


  async getTimeLeftToPoolRewardChange() {
    const response = await api.get(`api/AUTH/getTimeLeftToPoolRewardChange`)

    if (response.status !== 200) apiError(response)

    return response.data
  }

}

export default new AuthToken()
