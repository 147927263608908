import React, { CSSProperties, FC, useEffect, useState } from 'react'

import { useGoogleLogin } from '@react-oauth/google'
import { useWeb3Modal } from '@web3modal/react'
import { observer } from 'mobx-react'
// @ts-ignore
import { appleAuthHelpers } from 'react-apple-signin-auth'
import { useNavigate } from 'react-router-dom'

import cn from 'classnames'
import { toHex } from 'viem'
import { useAccount } from 'wagmi'

import { invitation_change_nickname, restore_account } from '../../utils/routes'
import { LoginProvider, TwitterEventType } from 'utils'

import { toast } from 'App'
import ButtonNew from 'components/ButtonNew'
import Modal from 'components/Modal'
import { ModalPWA } from 'componentsPWA/ModalPWA'

import { useStore } from 'store'

import { ReactComponent as Apple } from 'sources/images/apple.svg'
import Google from 'sources/images/googleColor.svg'
import Metamask from 'sources/images/mm_logo.svg'
import { ReactComponent as Twitter } from 'sources/images/twitter.svg'
import { ReactComponent as WalletConnect } from 'sources/images/walletConnect.svg'

import styles from './styles.module.scss'

const { ethereum } = window

const loginTypes = [
  {
    type: LoginProvider.Google,
    icon: <img src={Google} alt="google" className={styles.typeIcon} />,
  },
  {
    type: LoginProvider.Apple,
    icon: (
      <Apple
        className={styles.typeIcon}
        style={{ width: '21px', minWidth: '21px' }}
      />
    ),
  },
  {
    type: LoginProvider.Twitter,
    icon: <Twitter className={cn(styles.typeIcon, styles.fillIcon)} />,
  },
  {
    type: LoginProvider.Metamask,
    icon: <img src={Metamask} alt="metamask" className={styles.typeIcon} />,
  },
  {
    type: LoginProvider.WalletConnect,
    icon: <WalletConnect className={cn(styles.typeIcon, styles.fillIcon)} />,
  },
]

const connectTypes = [
  {
    type: LoginProvider.Metamask,
    icon: <img src={Metamask} alt="metamask" className={styles.typeIcon} />,
  },
  {
    type: LoginProvider.WalletConnect,
    icon: <WalletConnect className={cn(styles.typeIcon, styles.fillIcon)} />,
  },
]

interface IProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  type?: 'logIn' | 'connect'
  connectedWallet?: string
}

//Resize
export const LogInModal: FC<IProps> = observer(
  ({ isOpen, setIsOpen, type = 'logIn', connectedWallet }) => {
    const {
      mediaQuery,
      loading,
      login: loginStore,
      profile: profileStore,
      main: mainStore,
    } = useStore()
    const navigate = useNavigate()

    const [isClickWalletConnectButton, setClickWalletConnectButton] =
      useState(false)

    // WalletConnect
    const { address, connector, isConnected } = useAccount()
    const { open: openWeb3Modal, close: closeWeb3Modal } = useWeb3Modal()

    const closeLoginModal = () => {
      setIsOpen(false)
    }

    const onClickLogin = (type: LoginProvider) => {
      if (type === LoginProvider.Google) {
        handleGoogleLogin()
      }

      if (type === LoginProvider.Apple) {
        handleAppleLogin()
      }

      if (type === LoginProvider.Twitter) {
        handleTwitterLogin()
      }

      if (type === LoginProvider.Metamask) {
        handleMetamaskLogin()
      }

      if (type === LoginProvider.WalletConnect) {
        handleWalletConnectLogin()
      }
    }

    const onLoginSuccess = (response: any) => {
      const message =
        type === 'connect'
          ? 'You have successfully connected wallet'
          : 'You have successfully logged in'
      if (response) {
        closeLoginModal()
        const { Item1: user } = response

        if (
          user?.AbleToChangeNickname &&
          sessionStorage.getItem('newRegisteredProfile')
        ) {
          navigate(invitation_change_nickname())
        } else if (user?.DeletionMark) {
          navigate(restore_account())
        } else {
          navigate(`/user/${user.NickName}`)
        }

        toast({
          type: 'success',
          message,
        })
      }
    }

    // ------------------------------------------------------------------------------------------------------------------

    // GOOGLE ---------------------------------------⬇︎⬇︎⬇︎
    const handleGoogleLogin = useGoogleLogin({
      onSuccess: async codeResponse => {
        try {
          loading.setLoading(true)
          const response = await loginStore.loginGoogle(
            codeResponse.access_token
          )
          onLoginSuccess(response)
          loading.setLoading(false)
        } catch (e: any) {
          loading.setLoading(false)
          toast({
            type: 'warning',
            message: e.message || 'Log In Failed',
          })
          console.log('e', e)
        }
      },
      onError: error => {
        toast({
          type: 'warning',
          message: 'Log In Failed',
        })
        console.log('Log In Failed:', error)
      },
    })
    // GOOGLE ---------------------------------------⬆︎⬆︎⬆︎

    // ------------------------------------------------------------------------------------------------------------------

    // APPLE ---------------------------------------⬇︎⬇︎⬇︎
    const handleAppleLogin = async () => {
      try {
        appleAuthHelpers.signIn({
          authOptions: {
            clientId: 'io.authencity.app.login.identifier',
            redirectURI: window.location.origin,
            scope: 'email name',
            state: 'state',
            nonce: 'nonce',
            usePopup: true,
          },
          onSuccess: async (appleResponse: any) => {
            try {
              loading.setLoading(true)
              const response = await loginStore.loginApple(
                appleResponse.authorization.id_token
              )
              onLoginSuccess(response)
              loading.setLoading(false)
            } catch (e: any) {
              loading.setLoading(false)
              toast({
                type: 'warning',
                message: e.message || 'Log In Failed',
              })
              console.log('e', e)
            }
          },
          onError: (e: any) => {
            console.log(e)
            if (e.error === 'popup_closed_by_user') return
            toast({
              type: 'warning',
              message: 'Log In Failed',
            })
          },
        })
      } catch (e) {}
    }
    // APPLE ---------------------------------------⬆︎⬆︎⬆︎

    // ------------------------------------------------------------------------------------------------------------------

    // TWITTER ---------------------------------------⬇︎⬇︎⬇︎
    const handleTwitterLogin = async () => {
      try {
        const twitterUrl = await profileStore.requestToken(
          TwitterEventType.Login
        )
        sessionStorage.setItem('log_in_twitter', String(new Date()))
        // window.location.href = twitterUrl
        const win = window.open(twitterUrl, '_self')
        if (win) {
          win.focus()
        }
      } catch (e) {
        toast({
          type: 'warning',
          message: 'Log In Failed',
        })
      }
    }
    // TWITTER ---------------------------------------⬆︎⬆︎⬆︎

    // ------------------------------------------------------------------------------------------------------------------

    // 2 type fo connect
    // CONNECT WALLET (Metamask & WalletConnect)------------------------------------⬇︎⬇︎⬇︎
    const connectWallet = async (
      wallet: string,
      provider: any,
      typeProvider: LoginProvider
    ) => {
      try {
        const isWalletRegistered =
          await loginStore.checkWalletIsRegisteredInSystem(wallet)

        if (isWalletRegistered && !connectedWallet) {
          return toast({
            type: 'warning',
            message:
              'This wallet is already connected with another profile in our system. Please, choose another wallet!',
          })
        } else if (
          isWalletRegistered &&
          connectedWallet &&
          connectedWallet !== wallet
        ) {
          return toast({
            type: 'warning',
            message: 'Select the wallet that is linked to the profile!',
          })
        }

        const nonce = connectedWallet
          ? await loginStore.getNonce(wallet)
          : await loginStore.connectWallet(wallet, typeProvider)

        const params = [toHex(nonce.toString()), wallet]

        toast({
          type: 'success',
          message: 'Sign the message in Metamask',
        })
        provider
          .request({ method: 'personal_sign', params })
          .then(async (hash: any) => {
            // Don't use 2fa if type === 'connect'
            const response = await loginStore.loginWallet(
              wallet,
              hash,
              typeProvider,
              true
            )

            onLoginSuccess(response)
          })
          .catch((error: any) => {
            console.log('error', error)
            let message = error.message || ''
            if (message.match(/User rejected/i)) {
              message = 'User rejected the request.'
            }
            toast({
              type: 'warning',
              message: message || 'Connect Failed',
            })
          })
      } catch (e: any) {
        toast({
          type: 'warning',
          message: e.message || 'Connect Failed',
        })
      }
    }
    // CONNECT WALLET (Metamask & WalletConnect)------------------------------------⬆︎⬆︎⬆︎

    // ------------------------------------------------------------------------------------------------------------------

    // COMMON WALLET LOGIN (Metamask & WalletConnect)------------------------------------⬇︎⬇︎⬇︎
    const loginWithWallet = async (
      userWallet: string,
      provider: any,
      typeProvider: LoginProvider
    ) => {
      try {
        const isWalletRegistered =
          await loginStore.checkWalletIsRegisteredInSystem(userWallet)
        console.log('isWalletRegistered', isWalletRegistered)

        if (isWalletRegistered) {
          const nonce = await loginStore.getNonce(userWallet)
          console.log('nonce', nonce)

          const params = [toHex(nonce.toString()), userWallet]

          toast({
            type: 'success',
            message: 'Sign the message in Metamask',
          })
          provider
            .request({ method: 'personal_sign', params })
            .then(async (hash: any) => {
              console.log('hash', hash)
              const response = await loginStore.loginWallet(
                userWallet,
                hash,
                typeProvider
              )

              response && onLoginSuccess(response)
            })
            .catch((error: any) => {
              console.log('error', error)
              let message = error.message || ''
              if (message.match(/User rejected/i)) {
                message = 'User rejected the request.'
              }
              toast({
                type: 'warning',
                message: message || 'Log In Failed',
              })
            })
        } else {
          toast({
            type: 'warning',
            message:
              'This wallet is not associated with any profile in our system. Please, sign up!',
          })
        }
      } catch (e: any) {
        toast({
          type: 'warning',
          message: e.message || 'Log In Failed',
        })
      }
    }
    // COMMON WALLET LOGIN (Metamask & WalletConnect)------------------------------------⬆︎⬆︎⬆︎

    // ------------------------------------------------------------------------------------------------------------------

    // METAMASK ---------------------------------------⬇︎⬇︎⬇︎
    const handleMetamaskLogin = async () => {
      if (ethereum) {
        try {
          await ethereum.request({ method: 'eth_requestAccounts' })
          const userWallets = await ethereum.request({ method: 'eth_accounts' })
          const userWallet = userWallets[0]

          if (type === 'connect') {
            await connectWallet(userWallet, ethereum, LoginProvider.Metamask)
          } else {
            await loginWithWallet(userWallet, ethereum, LoginProvider.Metamask)
          }
        } catch (error: any) {
          console.log('error', error)
          let message = error.message || ''
          if (message.match(/Already processing/i)) {
            message =
              'The MetaMask extension is already open and awaiting to be unlocked'
          }
          toast({
            type: 'warning',
            message: message || 'Log In Failed',
          })
        }
      } else {
        toast({
          type: 'warning',
          message:
            'This feature is not available because you are not using MetaMask',
        })
      }
    }
    // METAMASK ---------------------------------------⬆︎⬆︎⬆︎

    // ------------------------------------------------------------------------------------------------------------------

    // WALLET CONNECT ---------------------------------------⬇︎⬇︎⬇︎
    useEffect(() => {
      if (isConnected) {
        isClickWalletConnectButton && onWalletConnectConnected()
      }
    }, [isConnected])

    const onWalletConnectConnected = async () => {
      if (connector && isConnected && address) {
        const provider = await connector.getProvider()

        if (type === 'connect') {
          await connectWallet(address, provider, LoginProvider.WalletConnect)
        } else {
          await loginWithWallet(address, provider, LoginProvider.WalletConnect)
        }
      }
    }

    const handleWalletConnectLogin = async () => {
      if (isConnected) {
        await onWalletConnectConnected()
      } else {
        setClickWalletConnectButton(true)
        await openWeb3Modal()
      }
    }
    // WALLET CONNECT ---------------------------------------⬆︎⬆︎⬆︎

    const types = type === 'connect' ? connectTypes : loginTypes
    const title = type === 'connect' ? 'Connect' : 'Log in with'

    const modalContent = (containerStyle?: CSSProperties) => (
      <div className={styles.container} style={containerStyle}>
        <div className={styles.title}>{title}</div>
        <div className={styles.loginTypes}>
          {types.map(item => (
            <ButtonNew
              key={item.type}
              onClick={() => onClickLogin(item.type)}
              btnStyleType="style_6"
              btnStyle={{
                height: mediaQuery.minWidth_2200 ? '60px' : '50px',
                width: '100%',
                boxShadow: '0px 7px 25px rgba(0, 0, 0, 0.05)',
              }}
              btnText={
                <>
                  {item.icon}
                  {item.type}
                </>
              }
            />
          ))}
        </div>
      </div>
    )

    return (
      <>
        {mainStore.isPWA ? (
          <ModalPWA
            open={isOpen}
            onClose={closeLoginModal}
            closeButton
            disableOutsideClick
            // fullScreen
            paperSX={{ justifyContent: 'center' }}
          >
            {modalContent()}
          </ModalPWA>
        ) : (
          <Modal
            size="responsive"
            contentStyle={{ maxWidth: 'calc(min(380px, 95vw))' }}
            onClose={closeLoginModal}
            open={isOpen}
            closeButton
            closeButtonStyle={{ left: 'unset', right: '15px' }}
            blockOutsideClose
            withAnimation
          >
            {modalContent({ padding: '32px' })}
          </Modal>
        )}
      </>
    )
  }
)
